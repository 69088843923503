import React from 'react';

import styles from './sobrestyle.module.css';

function Sobre(props){

  return(
    <div className={styles.sobreText}>
      <h1>Sobre</h1>
      <p style={{marginTop:'20px'}}>Este site é um projeto em desenvolvimento contínuo, idealizado e mantido por uma pessoa com o propósito de oferecer uma alternativa superior às plataformas existentes. Criado com foco na qualidade e na experiência do usuário, o site se destaca por proporcionar uma renderização aprimorada tanto para imagens quanto para equações, garantindo maior clareza e precisão nos estudos.</p>
      <p>Estamos trabalhando para desenvolver uma integração mais robusta entre conteúdos e questões, facilitando o acesso a materiais complementares e tornando o aprendizado mais coeso. Além disso, uma melhor interação do usuário com o que precisa estudar está em nossos planos, incluindo a implementação de filtros personalizados e análises detalhadas para orientar o estudante sobre quais questões deve focar para maximizar seu desempenho.</p>
      <p>Este é apenas o começo, e o site continuará evoluindo para atender cada vez melhor às necessidades dos estudantes.</p>
    </div>
  )
}

export default Sobre;
