import React from 'react';
import EnemMarkdown from '../EnemMarkdown.jsx';

import styleQuestao from './questao.module.css';


import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Questao(props) {
  const letras = ['A','B','C','D',"E"]
  const [alternativaMarcada,setAlternativaMarcada] = React.useState(false);
  const [respondida, setRespondida] = React.useState(false)

  function updateAlternativa(marcada){
    if (!respondida){
      if (marcada !== alternativaMarcada){setAlternativaMarcada(marcada)}
      else{setAlternativaMarcada(false)}
    }
  }

  return (
    <div className={styleQuestao.questao}>
      <Card className={styleQuestao.questaoCard}>
        <p><b>Questão {props.numeroQuestao}</b></p>
        <span>Prova: {props.questao.prova} ({props.questao.ano}) &nbsp; Área: {props.questao.area}</span>
        <hr/>
        <EnemMarkdown>{props.questao.texto}</EnemMarkdown>
        <div style={{marginTop:'40px'}}>
          {props.questao.alternativas.map(
            (alternativaTexto,index)=> <Alternativa index={index} alternativaTexto={alternativaTexto} updateAlternativa={updateAlternativa} alternativaMarcada={alternativaMarcada} key={props.questaoIndex+'alternativa'+index}/>
          )}
        </div>
        <Row>
          <Col lg={4} md={6} sm={12}>
            <Button style={{width:'100%', backgroundColor:'black'}} disable={(false===alternativaMarcada).toString()} onClick={()=>setRespondida(true)}>Responder</Button>
          </Col>
          <Col>
            <div style={{marginTop:'5px'}}>
            {respondida?
              (alternativaMarcada===props.questao.alternativaCorreta?
                <span style={{color:'green'}}><b>Acertou!!!</b> {'\u00A0'} <i className="fa-solid fa-check" /></span>
                :<div style={{color:'red'}}><p><b>Errou!!!</b> {'\u00A0'} <i className="fa-solid fa-xmark"/> {'\u00A0'} A resposta correta é a letra <b>{letras[props.questao.alternativaCorreta]}</b></p></div>
              )
              :''
            }</div>
          </Col>
        </Row>
        <hr/>
        <RespostaView resposta={props.questao.resposta}/>
      </Card>
    </div>
  )

}

function Alternativa(props){
  const letras = ['A','B','C','D','E'];
  const id = `alt${letras[props.index]}${Math.floor(Math.random()*999999)}`
  function marcando(){
    props.updateAlternativa(props.index)
  }

  return (
    <div style={{display:'flex'}}>
      <div style={{width:'50px'}}>
        <input type='checkbox' id={id} style={{display:'none'}} checked={props.alternativaMarcada===props.index} onChange={marcando}/>
        <label className={styleQuestao.letraCheck}
        style={props.alternativaMarcada===props.index?{backgroundColor:'orange'}:{backgroundColor:'black'}}
        htmlFor={id}>
          <span className={styleQuestao.letraInterna}>{letras[props.index]}</span>
        </label>
      </div>
      <div style={{marginLeft:'10px'}}>
        <EnemMarkdown>
        {props.alternativaTexto}
        </EnemMarkdown>
      </div>

    </div>

  )

}

function RespostaView(props){

  const [showResposta, setShowResposta] = React.useState(false)


  return (
    <div>
      <a className={styleQuestao.respostaView} onClick={()=>setShowResposta(!showResposta)}>
        <i className="fa-solid fa-chalkboard-user"/>
        <span>{showResposta?'Esconder resposta':'Ver resposta'}</span>
      </a>
      {showResposta?<RespostaCard resposta={props.resposta}/>:null}
    </div>


  )

}

function RespostaCard(props){

  return(
    <Card style={{padding:'5px',marginTop:'15px'}}>
        <EnemMarkdown>
        {props.resposta}
        </EnemMarkdown>
    </Card>
  )
}

export default Questao;
