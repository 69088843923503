import React from 'react';
import EnemMarkdown from '../EnemMarkdown';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import axios from 'axios';

import styles from './conteudoStyle.module.css';

class Conteudo extends React.Component{

  constructor(props){
    super(props);
    this.state={
      identificador:"",
      texto:"",
      imagens:[],
      parents:[],
      tages:[]
    };
  }

  updateData = ()=>{
    let identificador = window.location.href.split('/');
    identificador = identificador[identificador.length-1];
    identificador = decodeURI(identificador)
    // let par = {}
    // for(var pair of query.entries()) {
    //   par[pair[0]] = pair[1];
    // }
    // // console.log(par)
    console.log(identificador);
    axios.get('/conteudo',{
      params: {identificador:identificador}
    })
    .then((res) => {
      if(res.data){
        this.setState(res.data)
      }
    })
    .catch((er)=>alert(er))
  }

  componentDidMount(){
    this.updateData()
  }

  render(){
    return(
      <div>
        <Card className={styles.conteudoCard}>
          <h1>{this.state.identificador}</h1>
          <EnemMarkdown>
          {this.state.texto}
          </EnemMarkdown>
        </Card>
      </div>
    )
  }

}

export default Conteudo;
