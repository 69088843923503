import React from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import axios from 'axios';
import styles from './styleLogin.module.css';

function Login(props){

  const [user,setUser] = React.useState(
    {
      username:'',
      password:''
    }
  )

  function loginPressed(event){
    axios.post("/login",user,{
      withCredentials: true,
    })
    .then(function(res){
      if (res.status == 200){
        console.log(res)
        window.location.href = '/';
      }
    }).catch((res)=>alert(res))
    event.preventDefault();
  }

  return(
    <div className={styles.loginStyle}>
      <Form onSubmit={loginPressed}>
        <Form.Group>
          <Form.Label>Usuário</Form.Label>
          <Form.Control value={user.username} onChange={(e)=>{setUser((prev)=>({...prev,username:e.target.value}))}}/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Senha</Form.Label>
          <Form.Control type='password' value={user.password} onChange={(e)=>{setUser((prev)=>({...prev,password:e.target.value}))}}/>
        </Form.Group>
        <Button className={styles.buttonLoginRegistrar} type='submit'>Login</Button>
      </Form>
    </div>
  )
}


export default Login;
