import React from 'react';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import styles from './editor.module.css';

import Questao from '../../Questao/Questao'
import UploadImages from '../UploadImages.jsx'

import axios from 'axios';


function EditorDeQuestao(){

  function questaoPadrao(){
    return (
      {identificador:`Questao20##${(Math.random() + 1).toString(36).substring(2,10)}`,
        texto:"Edite a questão",
        alternativas:[
          'Alternativa A',
          'Alternativa B',
          'Alternativa C',
          'Alternativa D',
          'Alternativa E'
        ],
        alternativaCorreta:'',
        resposta:'Escreva a resposta',
        imagens:[],
        tags:[],
        ano:'20',
        prova:'',
        area:'',
      }
    )
  }

  const [questaoSelecionada,editarQuestaoSelecionada] = React.useState(false);
  const [questoesIdentificadores, editarQuestoesIdentificadores] = React.useState([]);
  const [questao, editarQuestao] = React.useState(questaoPadrao());


  React.useEffect(
    ()=>{
      axios.get('/questaoIdentificadores')
      .then(
        (res)=>{
            editarQuestoesIdentificadores(res.data)
            // console.log(res.data)
        }
      )
      .catch((err)=>alert(err));
    },[]
  )


  function formHandle(event){
    const questaoSelecionada = event.target.value
    if (questaoSelecionada!=0){
      axios.get(`/questao?id=${questaoSelecionada}`)
        .then(
          (res)=>{
            editarQuestaoSelecionada(questaoSelecionada);
            editarQuestao(res.data)
          }
        )
        .catch((err)=>alert(err))
    }
    else{
      editarQuestaoSelecionada(questaoSelecionada);
      editarQuestao(questaoPadrao());
    }
  }





    return(
      <Container>
        <h1 style={{textAlign:'center', marginBottom:'30px'}}>Editor de Questões</h1>
        <div style={{marginTop:'70px', width:'50%', marginLeft:'auto', marginRight:'auto'}}>
          <Form.Select value={questaoSelecionada}
          onChange={formHandle}
          title="Selecione uma opção">
            <option value={0}>Nova questão</option>
            {questoesIdentificadores.map(
              (doc)=><option value={doc._id} key={`option${doc._id}`}>{doc.identificador}</option>
            )}
          </Form.Select>
        </div>
        <Editor questao={questao} editarQuestao={editarQuestao}/>
      </Container>
    )
}

function Editor(props){

    return (
          <div>
            <StrictEditor questao={props.questao} editarQuestao={props.editarQuestao} />
            <Questao questao={props.questao} />
          </div>
      )
}

// ------StrictEditor---------
function StrictEditor(props){

  const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
  const alternativas = [0,1,2,3,4];
  function sendQuestao(){
    if (alternativas.indexOf(props.questao.alternativaCorreta)!=-1) {

      if (props.questao._id){
        const questaoEnviar = {...props.questao}
        delete questaoEnviar._id
        axios.put(`/questao?id=${props.questao._id}`, questaoEnviar,{withCredentials:true})
          .then((res)=>{
            alert(res.data);
          })
          .catch((err)=>alert(err))
      }
      else{
        axios.post(`${process.env.REACT_APP_BACKEND}/questao`,props.questao,{withCredentials:true})
        .then((res)=>{
            alert(res.data.msg);
            props.editarQuestao({...props.questao,_id:res.data._id})
        })
        .catch((res)=>{
          alert('Algo deu errado');
        })
      }


    }
    else {
      alert('Selecione uma alternativa para ser correta')
    }

  }

  return (
      <Card className={styles.cardEditor}>
        <Card.Body>
          <h3>Editor</h3>
          <Form>
            <Form.Group>
              <Form.Label>Identificador</Form.Label>
              <p>Id BD = {props.questao._id?props.questao._id:'Não salva'}</p>
              <Form.Control value={props.questao.identificador}
              onChange={(e)=>props.editarQuestao({...props.questao,identificador:e.target.value})}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Ano</Form.Label>
              <Form.Control value={props.questao.ano}
              onChange={(e)=>props.editarQuestao({...props.questao,ano:e.target.value})}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Area</Form.Label>
              <Form.Control value={props.questao.area}
              onChange={(e)=>props.editarQuestao({...props.questao,area:e.target.value})}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Prova</Form.Label>
              <Form.Control value={props.questao.prova}
              onChange={(e)=>props.editarQuestao({...props.questao,prova:e.target.value})}/>
            </Form.Group>
            <Form.Group style={{marginTop:'10px'}}>
              <Form.Label>Enunciado</Form.Label>
              <Form.Control as='textarea' style={{resize:'none'}} value={props.questao.texto} rows={10}
              onChange={(e)=>props.editarQuestao({...props.questao,texto:e.target.value})}
              />
            </Form.Group>
            <Alternativas questao={props.questao} editarQuestao={props.editarQuestao}/>
            <Resposta questao={props.questao} editarQuestao={props.editarQuestao}/>
            <UploadImages documento={props.questao} editarDocumento={props.editarQuestao}/>
          </Form>
          <Button style={{marginTop:'15px'}} onClick={sendQuestao}> Enviar Questão </Button>
          {props.questao._id?
            <Button style={{marginTop:'15px', marginLeft:'30px'}} onClick={()=>setModalDeleteShow(!modalDeleteShow)}> Deletar questao </Button>:
            null
          }
        </Card.Body>
        <ModalDeleteQuestao show={modalDeleteShow} questaoId={props.questao._id} onHide={()=>setModalDeleteShow(!modalDeleteShow)}/>
      </Card>
  )

}

function ModalDeleteQuestao(props){

  function deleteQuestao(){
    axios.delete(`/questao?id=${props.questaoId}`,{withCredentials:true})
    .then((res)=>{
      alert(res.data)
      window.location.reload()
    })
    .catch((res)=>{alert(res.data)})
  }

  return(
    <Modal show={props.show}>
      <Modal.Body>Deseja realmente deletar a questão</Modal.Body>
      <Modal.Footer>
      <Button onClick={deleteQuestao}>Sim</Button>
      <Button onClick={props.onHide}>Não</Button>
      </Modal.Footer>
    </Modal>
  )
}

function Alternativas(props){
  const indexLetras = [0,1,2,3,4];
  const letras = ["A","B","C","D","E"];

  return (
    <Form.Group style={{marginTop:'10px'}}>
      <Form.Label>Alternativas</Form.Label>
      {indexLetras.map((indexLetras) => <Alternativa key={`alternativa${indexLetras}`} letra={letras[indexLetras]} index={indexLetras} checked={props.questao.alternativaCorreta===indexLetras} questao={props.questao} editarQuestao={props.editarQuestao} />)}
    </Form.Group>
  )

}

function Alternativa(props){
  const id = `alt${Math.floor(Math.random()*999999)}`

  function marcando(){
    props.updateAlternativa(props.index)
  }

  function mudarAlternativaCorreta(){
    if (props.questao.alternativaCorreta===props.index){
      props.editarQuestao({...props.questao,alternativaCorreta:null})
    }
    else{
      props.editarQuestao({...props.questao,alternativaCorreta:props.index})
    }
  }

  function mudarTextoAlternativa(e){
    var questao = {...props.questao}
    questao.alternativas[props.index] = e.target.value
    props.editarQuestao(questao)
  }

  return (
    <Row style={{marginTop:'15px'}}>
      <Col lg={1} md={1} xs={1}>
          <input type="checkbox" style={{display:'none'}} checked={props.checked} id={id} onChange={mudarAlternativaCorreta} />
          <label className={styles.letraCheck} style={props.checked?{backgroundColor:'orange'}:{backgroundColor:'black'}} htmlFor={id}>{props.letra}</label>
      </Col>
      <Col>
          <Form.Control as='textarea' style={{resize:'none'}} value={props.questao.alternativas[props.index]} onChange={mudarTextoAlternativa}  rows={4} />
      </Col>
    </Row>

  )
}

function Resposta(props){


  return (
    <Form.Group style={{marginTop:'20px'}}>
      <p><b>Resposta</b></p>
      <Form.Control as='textarea' style={{resize:'none'}} value={props.questao.resposta} rows={10}
      onChange={(e)=>props.editarQuestao({...props.questao,resposta:e.target.value})}
      />
    </Form.Group>
  )
}

/// EditorView


export default EditorDeQuestao;
