import React from 'react';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/Modal';

import styles from './editor.module.css';

import UploadImages from '../UploadImages.jsx'

import EnemMarkdown from '../../EnemMarkdown.jsx'

import axios from 'axios';


class EditorDeConteudo extends React.Component{

  conteudoPadrao = () => {
    return (
      {identificador:`A${(Math.random() + 1).toString(36).substring(2,10)}`,
        texto:"Edite o conteudo",
        imagens:[],
        parents:[],
        tags:[]}
    )
  }

  constructor(props){
    super(props);
    this.state={
      conteudoSelecionado: false,
      conteudoIdentificadores:[],
      conteudo: this.conteudoPadrao()
    }
  }

  componentDidMount(){
    axios.get('/conteudoIdentificadores')
    .then(
      (res)=>{
          this.setState({...this.state,conteudoIdentificadores:res.data})
          // console.log(res.data)
      }
    )
    .catch((err)=>alert(err))
  }

  editarConteudo = (conteudo)=>{
    this.setState({...this.state,conteudo:conteudo})
  }

  formHandle = (event) => {
    const conteudoSelecionado = event.target.value
    if (conteudoSelecionado!=0){

      axios.get(`/conteudoById?id=${conteudoSelecionado}`)
        .then(
          (res)=>{
            this.setState(
              {
                ...this.state,
                conteudoSelecionado:conteudoSelecionado,
                conteudo:res.data
              }
            )
          }
        )
        .catch((err)=>alert(err))
    }
    else this.setState({...this.state,conteudoSelecionado:conteudoSelecionado,conteudo:this.conteudoPadrao()})
  }

  render(){
    return(
      <Container>
        <h1 style={{textAlign:'center', marginBottom:'30px'}}>Editor de conteudo</h1>
        <div style={{marginTop:'70px', width:'50%', marginLeft:'auto', marginRight:'auto'}}>
          <Form.Select value={this.state.conteudoSelecionada}
          onChange={this.formHandle}
          title="Selecione uma opção">
            <option value={0}>Nova conteudo</option>
            {this.state.conteudoIdentificadores.map(
              (doc)=><option value={doc._id} key={`option${doc._id}`}>{doc.identificador}</option>
            )}
          </Form.Select>
        </div>

        <Editor conteudo={this.state.conteudo} editarConteudo={this.editarConteudo}/>
      </Container>
    )
  }

}

function Editor(props){

    return (
          <div>
            <StrictEditor conteudo={props.conteudo} editarConteudo={props.editarConteudo} />
            <Card style={{marginTop:'100px', padding:'25px'}}>
              <EnemMarkdown>{props.conteudo.texto}</EnemMarkdown>
            </Card>
          </div>
      )
}

// ------StrictEditor---------
function StrictEditor(props){

  const [modalDeleteShow, setModalDeleteShow] = React.useState(false);

  function sendconteudo(){

    if (props.conteudo._id){
      const conteudoEnviar = {...props.conteudo}
      delete conteudoEnviar._id
      axios.put(`${process.env.REACT_APP_BACKEND}/conteudo?id=${props.conteudo._id}`, conteudoEnviar)
        .then((res)=>alert(res.data))
        .catch((err)=>alert(err))
    }
    else{
      axios.post(`${process.env.REACT_APP_BACKEND}/conteudo`,props.conteudo)
      .then((res)=>{
          alert(res.data.msg);
          console.log(res.data)
          props.editarConteudo({...props.conteudo,_id:res.data._id});
      })
      .catch((res)=>{
        alert(res);
      })
    }


  }

  return (
    <Card className={styles.cardEditor}>
      <Card.Body>
        <h3>Editor de conteudo</h3>
        <Form>
          <Form.Group>
            <Form.Label>Identificador</Form.Label>
            <p>Id BD = {props.conteudo._id?props.conteudo._id:'Não salva'}</p>
            <Form.Control value={props.conteudo.identificador}
            onChange={(e)=>props.editarConteudo({...props.conteudo,identificador:e.target.value})}/>
          </Form.Group>
          <Form.Group style={{marginTop:'10px'}}>
            <Form.Label>Texto</Form.Label>
            <Form.Control as='textarea' style={{resize:'none'}} value={props.conteudo.texto} rows={10}
            onChange={(e)=>props.editarConteudo({...props.conteudo,texto:e.target.value})}
            />
          </Form.Group>
          <UploadImages documento={props.conteudo} editarDocumento={props.editarConteudo}/>
        </Form>
        <Button style={{marginTop:'15px'}} onClick={sendconteudo}> Enviar Conteudo
        </Button>
        {props.conteudo._id?
          <Button style={{marginTop:'15px', marginLeft:'30px'}} onClick={()=>setModalDeleteShow(!modalDeleteShow)}> Deletar conteúdo </Button>:
          null
        }
      </Card.Body>
        <ModalDeleteConteudo show={modalDeleteShow} conteudoId={props.conteudo._id} onHide={()=>setModalDeleteShow(!modalDeleteShow)}/>
    </Card>
  )

}

function ModalDeleteConteudo(props){

  function deleteConteudo(){
    axios.delete(`/conteudo?id=${props.conteudoId}`)
    .then((res)=>{
      alert(res.data)
      window.location.reload()
    })
    .catch((res)=>{alert(res.data)})
  }

  return(
    <Modal show={props.show}>
      <Modal.Body>Deseja realmente deletar a questão</Modal.Body>
      <Modal.Footer>
      <Button onClick={deleteConteudo}>Sim</Button>
      <Button onClick={props.onHide}>Não</Button>
      </Modal.Footer>
    </Modal>
  )
}



export default EditorDeConteudo;
