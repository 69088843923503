import React from 'react';
import {Card,Row, Col, Form, Button} from 'react-bootstrap';


import brainImage from './menina estudando azulenem.png';
import doctorImage from './medico free.png';
import doctorImageP from './medico free red.jpg';
import styles from './home.module.css';

function Home(){

  return(
    <div>
      <FrontPublicity />
      <ResumoPublicidade/>
      <FerramentasPlataforma/>
      <Icentivo/>
      <IcentivoProfissao/>
    </div>
  )
}

function FrontPublicity(){
  return(
    <div className={styles.frontPublicity}>
      <div className={styles.topLeftTextImage}>
        <h1>Resolva as questões do Enem e aprenda os assuntos relacionados</h1>
      </div>
      <img className={styles.frontImage} src={brainImage} />

    </div>
  )
}

function TempoParaEnem(){

  return(
    0
  )

}


function ResumoPublicidade(){

  return(
    <div className={styles.resumoPublicidade}>
      <p>Bem-vindo à nossa plataforma de estudos dedicada aos alunos que estão se preparando para o ENEM! Nossa missão é fornecer ferramentas eficientes e interativas para aprimorar seu aprendizado e garantir que você esteja totalmente preparado para o exame.</p>
    </div>
  )
}




function FerramentasPlataforma(){



  return(
    <div className={styles.ferramentasPlataforma}>
      <Ferramenta title='Resolva questões' color='#de1818' href='/questoes'>
      Nossas questões são renderizadas de forma otimizada, permitindo uma visualização clara e organizada que facilita o entendimento e a resolução.
      </Ferramenta>
      <Ferramenta title='Estude os conteudos' color='#00aeef' href='/conteudos'>
      Acesse resumos claros e concisos, organizados por matéria e tema, que se conectam para proporcionar uma compreensão mais completa e integrada dos tópicos.
      </Ferramenta>
    </div>
  )
}

function Ferramenta(props){

  function onClick(){
    if (props.href){
      window.location.href = props.href;
    }
    return
  }

  return(
      <Card className={styles.cardFerramenta} onClick={()=>onClick(props.href)}>
       <Card.Body>
         <Card.Title style={{color:props.color?props.color:'black'}}>
          {props.title}
         </Card.Title>
         <Card.Text style={{marginTop:'30px'}}>
           {props.children}
         </Card.Text>
       </Card.Body>
     </Card>
  )
}


function Icentivo(){
  return(
    <div className={styles.icentivo}>
      <p>
      Nossa plataforma é sua aliada na jornada para conquistar uma vaga nas melhores universidades e cursos do país. Com um preparo abrangente e eficiente, você terá todas as ferramentas necessárias para alcançar um desempenho de excelência no ENEM.
      </p>
    </div>

  )
}

function IcentivoProfissao(){
  return(
    <div className={styles.frontPublicity}>
      <div className={styles.topLeftTextImage}>
        <h2 className={`${styles.textImages} ${styles.textImageIcentivoProf}`}>Iremos te preparar para alcançar a carreira dos seus sonhos. Com recursos avançados e um estudo direcionado, você estará pronto para se destacar e garantir sua vaga nas melhores universidades, pavimentando o caminho para o sucesso profissional.
        </h2>
      </div>

      <img alt='Medico'
      className={`${styles.frontImage} ${styles.responsiveImgL}`}
      src={doctorImage}
      / >
      <img alt='Medico P'
      className={`${styles.frontImage} ${styles.responsiveImgP}`}
      src={doctorImageP}
      / >


    </div>
  )
}

export default Home;
