import React from 'react';

function FooterReact(){

  return(
  <footer className="py-3 my-4">
    <ul className="nav justify-content-center border-bottom pb-3 mb-3">
      <li className="nav-item"><a href="/" className="nav-link px-2 text-muted">Home</a></li>
      <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Pergutas e Respostas (Desativado)</a></li>
      <li className="nav-item"><a href="/sobre" className="nav-link px-2 text-muted">Sobre</a></li>
    </ul>
    <p className="text-center text-muted">© 2024 Enem Fácil Versão Alfa 1.0</p>
  </footer>
  )
}

export default FooterReact;
