import React from 'react';
import EnemMarkdown from '../EnemMarkdown';

import Card from 'react-bootstrap/Card';

import axios from 'axios';
import styles from './conteudoStyle.module.css';


function Conteudos(){

  const [conteudos, setConteudos] = React.useState([]);

  React.useEffect(
    ()=>{
      axios.get('/conteudoIdentificadores')
      .then((res)=>{
        setConteudos(res.data)
        // console.log(res.data)
      })
      .catch((err)=>alert(err));
    },[]
  )

  return(
    <div>
      <Card className={styles.conteudosCard}>
          <h1>Conteudos</h1>
          <p>Ainda em desevolvimento primário</p>
          <div className={styles.aConteudo}>
            {conteudos.map(
              (c,i) =>{
                // console.log(c)
                return(
                  <div>
                    <a key={'conteudo'+i} href={`/conteudo/${c.identificador}`}>{c.identificador}</a>
                    <br/>
                  </div>
                )
              }
            )}
            </div>
      </Card>
    </div>

  )

}

export default Conteudos;
