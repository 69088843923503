import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import axios from 'axios';
import styles from './uploadImages.module.css';

var srcBaseURL = "https://enemfacil.online/api";
if (process.env.NODE_ENV === 'development') {
    srcBaseURL = process.env.REACT_APP_BACKEND
}

function UploadImages(props){
  let [image, setImage] = React.useState({
    name:'',
    files:[]
  })

  // funcao que envia imagem ao backend-------------
  function editImage(){
    let formData = new FormData();
    // let imagefile = document.querySelector('#pictureImg').files[0];
    let imagefile = image.files[0];
    // imagefile.setName(image.name)
    // console.log(imagefile.name)
    if (!imagefile){
      alert('Insira uma imagem');
      return
    }
    let nome = props.documento.identificador +"img"+ image.name;
    formData.append('image',imagefile, nome);
    // formData.append('name',image.name);
    axios.post("/imagem",formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
        },
      }
    ).then((res)=>{
      alert(res.data);
      let imagens = props.documento.imagens;
      imagens = props.documento.imagens;
      let filename = imagefile.name.split('.');
      let extension = filename[filename.length-1];
      extension = extension=='jpg'?'jpeg':extension;
      imagens.push(nome+'.'+extension);
      props.editarDocumento({...props.documento, imagens:imagens})
    }).catch((res)=>{
      alert(res.data);
    })

  }
  // -------------------------
  // --Validacao da imagem
  async function imgExtensionValidation(e){
    let permitedExtensions = ['jpeg', 'png', 'jpg', 'gif','svg'];
    // let imagefile = document.querySelector('#pictureImg');
    let imagefiles=e.target.files;
    // console.log(imagefiles)
    if (imagefiles[0]){
      let name = imagefiles[0].name.split('.');
      let extension = name[name.length-1];
      // console.log(extension);
      if (!permitedExtensions.includes(extension)){
        alert('Extensão indevida');
        document.querySelector('#pictureImg').value ='';
        setImage({...image,files:[]})
      }
      else{
        setImage({...image,files:imagefiles})
        // console.log('tentativa salvar')
      }
    }
  }
  //---------------------------------



  return(
    <Form.Group style={{marginTop:'20px'}}>
      <p><b>Upload de Imagens</b></p>
      <p>Identificador da imagem</p>
      <Form.Control value={image.name} onChange={(e)=>setImage({...image,name:e.target.value})} />
      <div style={{marginTop:'15px'}}>
        <input type="file" id="pictureImg" accept=".jpg,.png,.jpeg,.gif,.svg" onChange={imgExtensionValidation}/>
        <Button style={{marginLeft:'15px'}} onClick={editImage}>Enviar</Button>
      </div>
      <ImagensDocumento documento={props.documento} editarDocumento={props.editarDocumento}/>
    </Form.Group>
  )
}

function ImagemDocumento(props){

  function handleClick(event){
      navigator.clipboard.writeText(encodeURI(props.src));
  }

  function handleDelete(event){
    axios.delete("/imagem/"+props.src)
    .then((res)=>{
      alert(res.data)
      props.removeImage()
    })
    .catch((err)=>{
      alert(err)
    })
  }

  return(
    <Card>
      <p>{props.src}</p>
      <Card>
        <Row>
          <Col style={{textAlign:'center'}}>
            <img className={styles.imgUpload} src={srcBaseURL+'/Imagem?name='+props.src}/>
          </Col>
          <Col>
            <div className={styles.buttonContainer}>
              <Button style={{width:'300px'}} onClick={handleDelete}>Delete</Button>
              <Button style={{width:'300px'}} onClick={handleClick}>Copiar Link</Button>
            </div>
          </Col>
        </Row>
      </Card>
    </Card>
  )
}

function ImagensDocumento(props){

  function removeImage(index){
    props.documento.imagens.splice(index,1);
    props.editarDocumento({...props.documento, imagens: props.documento.imagens})
  }

  return(
    <Card>
      {props.documento.imagens.map(
        (src,index)=>(<ImagemDocumento key={'imagem'+src  } src={src} removeImage={()=>removeImage(index)} />)
      )}
    </Card>
  )
}

export default UploadImages;
