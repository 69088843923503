import React from 'react';
import Questao from './Questao.jsx';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

function Questoes(props){

  const [data, setData] = React.useState(null)
  const [erroMsg, setErroMsg] = React.useState('Carregando questões')

  React.useEffect(
    ()=>{
      axios.get('/questoes').
      then((res) => setData(res.data)).
      catch((er)=>{
        setErroMsg('Não foi possível carregar as questões')
      })
    },[]
  )

  return(
    <div style={{padding:'50px'}}>
      {data?
        data.map(
        (questao,index)=>
          {
            return (<Questao numeroQuestao={index+1} questao={questao} key={"Qt"+index} questaoindex={index}/>)
          }
        ):
        <div style={{textAlign:'center'}}>
          <Spinner animation="border" />
          <p>{erroMsg}</p>
        </div>
      }
    </div>
  )



}

export default Questoes;
