import React from 'react';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import axios from 'axios';

import styles from './styleRegistrar.module.css';

const passwordStrong = require('../../enemFacilFunctions.js').passwordForce;



function Registrar(props){

  const [userInformationS, setUserInformationS] = React.useState({
     nome:'',
     username:'',
     cUsername:'',
     password:'',
     cPassword:''
  })

  const setUserInformation = (userInf)=>{
    setUserInformationS((prev)=>({...prev,...userInf}))
  }

  function formSubmit(event){
    if (!(userInformationS.password==userInformationS.cPassword)){
      alert('Senhas não conferem');
      event.preventDefault();
      return 0
    }
    if (!(userInformationS.username==userInformationS.cUsername)){
      alert('Emails não conferem');
      event.preventDefault();
      return 0
    }
    if (!passwordStrong(userInformationS.password)){
      alert('Senha muito fraca');
      event.preventDefault();
      return 0
    }
    let dataToSend = {...userInformationS};
    delete dataToSend.cPassword;
    delete dataToSend.cUsername;
    axios.post("/registro",dataToSend,{
      withCredentials: true,
    })
    .then(function(response){
      if (response.status == 200){
        alert(response.data);
        props.history.push('/login');
      }
    }).catch((res)=>{
      console.log(res);
    });
    event.preventDefault();
    return 0
  }




  return(
    <div>
        <Form className={styles.formRegistrar} onSubmit={formSubmit}>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control value={userInformationS.nome} onChange={(event)=>(setUserInformation({nome: event.target.value}))}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control  type='email' value={userInformationS.username} onChange={(event)=>(setUserInformation({username: event.target.value}))}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirmar Email</Form.Label>
            <Form.Control value={userInformationS.cUsername} onChange={(event)=>(setUserInformation({cUsername: event.target.value}))}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>Senha</Form.Label>
            <Form.Control type='password' value={userInformationS.password} onChange={(event)=>(setUserInformation({password: event.target.value}))}/>
            {!passwordStrong(userInformationS.password)?<div><i className="fas fa-exclamation-triangle"></i><p style={{display:'inline', fontSize:'10px'}}>Senha muito fraca</p></div>:null}
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirmar Senha</Form.Label>
            <Form.Control type='password' value={userInformationS.cPassword} onChange={(event)=>(setUserInformation({cPassword: event.target.value}))}/>
            {userInformationS.password===userInformationS.cPassword?null:<div><i className="fas fa-exclamation-triangle"></i><p style={{display:'inline', fontSize:'10px'}}>Senhas não conferem</p></div>}
          </Form.Group>
          <Button className={styles.buttonLoginRegistrar} variant="primary" type="submit">
          Registrar
          </Button>
        </Form>
    </div>
  )
}

export default Registrar;
