
function passwordForce(password){
  var score = 0;
  if (!password)
      return 'fraca';

  if (password.length>=8){
    score+=1
  }

  var variations = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
  }


  var variationCount = 0;
  for (var check in variations) {
      variationCount += (variations[check] == true) ? 1 : 0;
  }
  if (variationCount>=2){
    score+=1
  }
// pode user também arrays com a.every(i=>i)
  score = parseInt(score);

  return score>=2;
}

function checkEmail(email){
  return /\S+@\S+/.test(email)
}

module.exports = {
  passwordForce: passwordForce,
  checkEmail: checkEmail
}
