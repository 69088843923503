import React from 'react';
import {Navbar, Nav, NavDropdown, Button, Form} from 'react-bootstrap';
import {Link} from "react-router-dom";
import logo from './logo.png';
import axios from 'axios';

import styles from './navbarStyle.module.css';
import NavUser from './NavUser/NavUser.jsx';

function NavbarEnemFacil(props){
  function logout(){
    axios.get('/logout',{withCredentials:true})
    .then((res)=>{window.location.replace("/")})
    .catch((err)=>alert(err));
  }


  return (
    <Navbar expand="lg" className={styles.navbarTopStyle}>
      <Navbar.Brand as={Link} to="/"><img src={logo} className={styles.navBrandImgStyle} title='Enem Fácil'/> Enem Fácil</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className={`justify-content-end ${styles.navBarCItens}`} id="basic-navbar-nav">
        <Nav className={styles.navPortrait}>
          <Nav.Item>
            <Nav.Link as={Link} to="/questoes">Questões</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/conteudos">Conteudos</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            {props.isAuthenticated?<Nav.Link onClick={logout}>Logout</Nav.Link>:<Nav.Link as={Link} to="/acesso">Acessar</Nav.Link>}
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}


export default NavbarEnemFacil;
