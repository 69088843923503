 // import Questao from './Components/Questao.jsx'
import React from 'react';
import axios from 'axios';


import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import NavbarEnemFacil from './Components/NavbarEnemFacil/NavbarEnemFacil.jsx';
import Home from './Components/Home/Home.jsx';
import Questoes from './Components/Questao/Questoes.jsx';
import EditorDeQuestao from './Components/Editor/EditorDeQuestao/EditorDeQuestao.jsx';
import EditorDeConteudo from './Components/Editor/EditorDeConteudo/EditorDeConteudo.jsx';
import Conteudo from './Components/Conteudo/Conteudo.jsx';
import Conteudos from './Components/Conteudo/Conteudos.jsx';
import Acesso from './Components/Acesso/Acesso.jsx';
import FooterReact from './Components/FooterReact/FooterReact';
import Sobre from './Components/Sobre/Sobre.jsx';

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND
} else {
    axios.defaults.baseURL = "https://enemfacil.online/api"
}


function App (props){

  const [isAuthenticated, setIsAuthenticated] = React.useState(false)

  React.useEffect(
    ()=>{
      axios.get('/isAuthenticated',{withCredentials:true})
      .then(
        (res)=>{
          setIsAuthenticated(res.data);
          // console.log(res.data);
          }
        )
      .catch(
        (res)=>alert(res)
      )
    },[]
  )

  function logout(){
    setIsAuthenticated(false)
  }

  return (
      <div className="App">
        <Router>
          <Route path='/'>
            <NavbarEnemFacil isAuthenticated={isAuthenticated}/>
          </Route>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path='/questoes' component={Questoes}/>
            <Route path='/conteudo' component={Conteudo}/>
            <Route path='/acesso' component={Acesso}/>
            <Route path='/sobre' component={Sobre}/>
            {isAuthenticated==='adm'?
                <Route path='/editor' component={EditorDeQuestao}/>:
              null
            }
            {isAuthenticated==='adm'?
                <Route path='/editorC' component={EditorDeConteudo}/>:
              null
            }
            <Route path='/conteudos' component={Conteudos}/>
          </Switch>
        </Router>
        <FooterReact/>
      </div>
  )
}

export default App;
