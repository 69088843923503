import React from 'react';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import axios from 'axios';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

import styles from './enemMarkdown.module.css';

var srcBaseURL = "https://enemfacil.online/api"
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    srcBaseURL = process.env.REACT_APP_BACKEND
}

function EnemMarkdown(props){
  return(
    <ReactMarkdown
    rehypePlugins={[rehypeKatex]}
    remarkPlugins={[remarkGfm,remarkMath]}
    components={{
      del:"small",
      img:((props)=>{
        var imgWidthPercent = {};
        if (props.alt){
          imgWidthPercent = {width:`${props.alt}%`};
        }
        // let altprop = props.alt?{width:`${props.alt}%`}:{}
        return <img alt={props.src} className={styles.imgMark} style={imgWidthPercent} src={srcBaseURL+'/Imagem?name='+props.src} />
      }),
      a:(props)=><Anotacao {...props}/>,

    }}
    >
    {props.children}
    </ReactMarkdown>
  )
}

// Modificar de px para transform
function Anotacao(props){
  const popoverHoverFocus = (
  <Popover>
    <ReactMarkdown
    rehypePlugins={[rehypeKatex]}
    remarkPlugins={[remarkGfm,remarkMath]}
    components={{
      del:"small",
      img:((props)=>{
        let altprop = props.alt?{width:`${props.alt}%`}:{}
        return <img alt={props.src} className={styles.imgMark2} style={altprop} src={srcBaseURL+'/Imagem?name='+props.src} />
      })
    }}

    >
      {props.title}
    </ReactMarkdown>
  </Popover>
  );

  return (props.title?
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverHoverFocus}>
        <a {...props} href={`/conteudo/${props.href}`} title='' target="_blank"/>
      </OverlayTrigger>
      :
        <a {...props} href={`/conteudo/${props.href}`} title='' target="_blank"/>

  )
}


export default EnemMarkdown;
