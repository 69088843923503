import React from 'react';
import {Card,Row, Col, Form, Button} from 'react-bootstrap';
import {
  Route,
  Link
} from "react-router-dom";

import styles from './styleAcesso.module.css';
import garotaImg from './garota sentada espelhada.png';

import Login from './Login/Login.jsx';
import Registrar from './Registrar/Registrar.jsx';

function Acesso(props){

  function matchLastPath(cPath){
    let lPath = window.location.href;
    lPath = lPath.split('/');
    lPath = lPath[lPath.length-1];
    return lPath === cPath

  }

  return(
    <Row className={`${styles.acessoLayout}`}>
      <Col sm={true}>
        <img className={styles.imgAcesso} alt='garoto estudando' src={garotaImg} style={{width:'100%'}}/>
      </Col>
      <Col>
        <div className={styles.divButtonLR}>
          <Button as={Link} to={'/acesso'} className={styles.buttonLR}  style={matchLastPath('acesso')?{borderTop:'2px solid blue'}:{}} >Já tenho uma conta</Button>
          <Button as={Link} to={'/acesso/registrar'} className={styles.buttonLR} style={matchLastPath('registrar')?{borderTop:'2px solid blue'}:{}}>Criar uma conta</Button>
        </div>
        <Route exact path='/acesso'>
          <Login/>
        </Route>
        <Route path='/acesso/registrar'>
          <Registrar/>
        </Route>
      </Col>
    </Row>
  )
}



export default Acesso;
